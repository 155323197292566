import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Form,
  Media,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchUserMessageStart,
  saveChatMessageStart,
  userMessageAssetFilesUploadStart,
  userMessageAssetFileRemoveStart,
} from "../../../store/actions/ChatAction";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import { translate, t } from "react-multi-lang";

const SubscribersMessageIndex = (props) => {

  const [inputData, setInputData] = useState({});
  const [postFileData, setPostFileData] = useState([]);
  const [previewImage, setPreviewImage] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(false);

  const [paidPost, setPaidPost] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");

  const [fileUploadStatus, setFileUploadStatus] = useState(false);

  const [disableImage, setDisableImage] = useState(false);

  const [disableVideo, setDisableVideo] = useState(false);

  const [audioTitle, setAudioTitle] = useState("");

  const [audioThumbnail, setAudioThumbnail] = useState(false);

  const [disableAudio, setDisableAudio] = useState(false);
  const [error, setError] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const handleChangeAmount = (e) => {
    const value = e.target.value;
    setInputData({
      ...inputData,
      amount: value,
    })
    validateNumber(value);

  }

  const validateNumber = (value) => {
    if (value < 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    props.dispatch(fetchUserMessageStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !props.userMessage.loading && Object.keys(props.userMessage.data)) {
      setInputData({
        ...inputData,
        message_file_id: props.userMessage.data.message_file_id,
        message: props.userMessage.data.user_message.message,
        amount: props.userMessage.data.user_message.amount,
      });
    }
    setSkipRender(false)
  }, [props.userMessage]);


  useEffect(() => {
    if (props.fileUpload.loading === false && props.fileUpload.data.user_message_asset.length > 0) {
      let files = [];
      props.fileUpload.data.user_message_asset.map((value, i) => {
        files.push(value.asset_file);
      });
      setPostFileData(files);
      setInputData({
        ...inputData,
        message_file_id: props.fileUpload.data.message_file_id,
      });
    }
  }, [!props.fileUpload.loading]);

  useEffect(() => {
    if (props.fileRemove.loading === false) {
      let files = [];
      if (props.fileRemove.data.user_message_asset_files.length > 0) {
        props.fileRemove.data.user_message_asset_files.map((value, i) => {
          files.push(value);
        });
      } else {
        setFileUploadStatus(false);
        setDisableVideo(false);
        setDisableAudio(false);
        setPaidPost(false);
        setDisableImage(false);
        setPreviewImage(false);
        setVideoPreviewUrl(false);
        setAudioThumbnail(false);
      }
      setInputData({
        ...inputData,
        message_file_id: props.fileRemove.data.user_message_asset_ids,
      });
      setPostFileData(files);
    }
  }, [!props.fileRemove.loading]);

  const handleChangeImage = (event, fileType) => {
    let data_array = [];

    [...event.target.files].forEach((file, key) => {

      let name = 'file[' + key + ']';

      data_array[name] = file;

    });
    data_array['file_type'] = fileType;

    setPreviewImage(true);
    setFileUploadStatus(true);
    setPaidPost(true);
    setDisableVideo(true);
    setDisableAudio(true);
    props.dispatch(userMessageAssetFilesUploadStart(data_array));
  };

  const handleChangeVideo = (event, fileType) => {
    let data_array = [];

    [...event.target.files].forEach((file, key) => {

      let name = 'file[' + key + ']';

      data_array[name] = file;

    });

    data_array['file_type'] = fileType;
    setVideoPreviewUrl(true);
    setPaidPost(true);
    setFileUploadStatus(true);
    setDisableImage(true);
    setDisableAudio(true);
    props.dispatch(userMessageAssetFilesUploadStart(data_array));
  };


  const handleChangeAudio = (event, fileType) => {
    let data_array = [];

    [...event.target.files].forEach((file, key) => {

      let name = 'file[' + key + ']';

      data_array[name] = file;

    });

    data_array['file_type'] = fileType;
    setPaidPost(true);
    setFileUploadStatus(true);
    setAudioThumbnail(true);
    setDisableImage(true);
    setDisableVideo(true);
    props.dispatch(userMessageAssetFilesUploadStart(data_array));
  };

  const handleClose = (event, post_file) => {
    event.preventDefault();
    if (props.fileUpload.loadingButtonContent !== null) {
      const notificationMessage = getErrorNotificationMessage(
        t("file_being_uploaded")
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      setPostFileData([]);
      props.dispatch(
        userMessageAssetFileRemoveStart({
          file: post_file,
          user_message_asset_id: inputData.message_file_id,
        })
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (fileUploadStatus) {
      props.dispatch(
        saveChatMessageStart({
          message: inputData.message ? inputData.message : "",
          amount: inputData.amount ? inputData.amount : "",
          user_message_asset_id: inputData.message_file_id ? inputData.message_file_id : "",
        })
      );
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("please_upload_media_files")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <div className="notification-page create-post subscribers-message-sec" id="tabs">
      <Container>
        {localStorage.getItem("is_content_creator") == 2 ? (
          <div className="create-post-box">
            <Form onSubmit={handleSubmit} className="edit-profile-form">
              <Row className="align-items-center">
                <Col sm={12} md={12} className="mb-4">
                  {/* <div className="post-create-header">
                    <div className="pull-left">
                      <Link
                        className="bookmarkes-list notify-title"
                        to={"/home"}
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/icons/back.svg"
                          }
                          className="svg-clone"
                        />
                        {t("user_messages")}
                      </Link>
                    </div>
                  </div> */}
                  <div className="settings-personal-info-header">
                    <h3>{t("user_messages")}</h3>
                  </div>
                </Col>
                <Col sm={12} md={6}>

                  <div className="searchMentions-1">
                    <div className="PostEditor">
                      <FormControl
                        controlId="chat-input-area"
                        as="textarea"
                        rows={3}
                        placeholder={t("type_a_message")}
                        name="message"
                        className="chat-input"
                        style={{
                          overflow: "auto",
                          overflowWrap: "break-word",
                          height: "48px",
                        }}
                        value={inputData.message}
                        onChange={(event) => {
                          setInputData({
                            ...inputData,
                            message: event.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col sm={12} md={6} className="">
                  <Form.Group className="md-mrg-btm">
                    <label className="text-muted m-1 mb-3 mb-lg-3">
                      {t("price")}
                    </label>
                    <Form.Control
                      type="number"
                      placeholder={t("price")}
                      name="amount"
                      pattern="[0-9]*"
                      min="0"
                      inputmode="numeric"
                      value={inputData.amount}
                      width="50%"
                      onChange={handleChangeAmount}
                    />
                    {error && <p className="error-msg text-danger text-right">{t("should_not_be_less_than")}</p>}
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} className="mt-3 mt-lg-4">
                  {localStorage.getItem("is_content_creator") == 2 ? (
                    <div className="left-half post-write">
                      <Button>
                        <Form.Group className="mb-0">
                          <Form.Control
                            id="fileupload_photo"
                            type="file"
                            multiple="multiple"
                            // disabled={disableImage ? true : false}
                            accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                            onChange={(event) =>
                              handleChangeImage(event, "image")
                            }
                            name="post_files"
                          />
                          <Form.Label
                            id="attach_file_photo"
                            for="fileupload_photo"
                            className="chat-attach_file"
                            data-original-title="null"
                          >
                            {/* <Image
                          src="assets/images/icons/gallery.svg"
                          className="svg-clone"
                        /> */}
                            {/* <Image
                              src="assets/images/post/post-image-upload.svg"
                              className="svg-clone"
                            /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                            >
                              <path d="M19 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5zM5 2h14a3 3 0 013 3v14a2.951 2.951 0 01-.3 1.285l-9.163-9.163a5 5 0 00-7.072 0L2 14.586V5a3 3 0 013-3zm0 20a3 3 0 01-3-3v-1.586l4.878-4.878a3 3 0 014.244 0l9.163 9.164A2.951 2.951 0 0119 22z"></path>
                              <path d="M16 10.5A3.5 3.5 0 1012.5 7a3.5 3.5 0 003.5 3.5zm0-5A1.5 1.5 0 1114.5 7 1.5 1.5 0 0116 5.5z"></path>
                            </svg>
                          </Form.Label>
                        </Form.Group>
                      </Button>
                      <Button>
                        <Form.Group
                          className="mb-0"
                          controlId="formFileDisabled"
                        >
                          <Form.Control
                            id="fileupload_video"
                            type="file"
                            multiple="multiple"
                            // disabled={disableVideo ? true : false}
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={(event) =>
                              handleChangeVideo(event, "video")
                            }
                            name="post_files"
                          />
                          <Form.Label
                            id="attach_file_video"
                            for="fileupload_video"
                            className="chat-attach_file"
                            data-original-title="null"
                          >
                            {/* <Image
                          src="assets/images/icons/video.svg"
                          className="svg-clone"
                        /> */}
                            {/* <Image
                              src="assets/images/post/post-video-upload.svg"
                              className="svg-clone video-add-icon"
                            /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M22.903 6.538a1.97 1.97 0 00-2.077.188 1.008 1.008 0 00-.11.096l-1.757 1.773C18.748 6.03 16.618 4.001 14 4.001H5A5.004 5.004 0 000 9v6c0 2.757 2.243 5 5 5h9c2.629 0 4.768-2.047 4.962-4.627l1.756 1.754c.034.033.069.063.107.092a1.975 1.975 0 002.078.187A1.973 1.973 0 0024 15.632V8.314c0-.757-.42-1.437-1.097-1.775zM14 18H5c-1.654 0-3-1.346-3-3V9c0-1.654 1.346-3 3-3h9c1.654 0 3 1.346 3 3v6c0 1.654-1.346 3-3 3zm5-5.417v-1.189l3-3.028.025 7.238L19 12.582z"></path>
                            </svg>
                          </Form.Label>
                        </Form.Group>
                      </Button>
                      {videoTitle !== "" ? (
                        <div className="post-title-content create-post-video-title">
                          <h4>{videoTitle}</h4>
                        </div>
                      ) : null}
                      <Button>
                        <Form.Group
                          className="mb-0"
                          controlId="formFileDisabled"
                        >
                          <Form.Control
                            id="fileupload_audio"
                            type="file"
                            multiple="multiple"
                            // disabled={disableAudio ? true : false}
                            accept="audio/mp3,audio/*"
                            onChange={(event) =>
                              handleChangeAudio(event, "audio")
                            }
                            name="post_files"
                          />
                          <Form.Label
                            id="attach_file_audio"
                            for="fileupload_audio"
                            className="chat-attach_file"
                            data-original-title="null"
                          >
                            {/* <Image
                          src="assets/images/icons/audio.svg"
                          className="svg-clone"
                        /> */}
                            {/* <Image
                              src="assets/images/post/post-audio-upload.svg"
                              className="svg-clone"
                            /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M15 7h3v10h-3V7zm-5 17h3V0h-3v24zM20 4v16h3V4h-3zM5 19h3V5H5v14zm-5-4h3V9H0v6z"></path>
                            </svg>
                          </Form.Label>
                        </Form.Group>
                      </Button>
                      {audioTitle !== "" ? (
                        <div className="post-title-content create-post-video-title">
                          <h4>{audioTitle}</h4>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}

                  {previewImage == true && postFileData ?
                    <Row>
                      {postFileData.map((image, index) => (
                        <Col sm={12} md={6}>
                          <div className="post-img-preview-sec">
                            <Link to="#" onClick={(event) => handleClose(event, image)}>
                              <i className="far fa-times-circle"></i>
                            </Link>
                            <Image
                              alt="#"
                              key={index}
                              src={image}
                              className="post-video-preview"
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                    : null}
                  {videoPreviewUrl && postFileData ? (
                    <Row>
                      {postFileData.map((video, index) => (

                        <Col sm={12} md={12}>

                          <div key={index} className="post-img-preview-sec my-3 my-lg-4">
                            <video
                              autoplay
                              controls
                              id="myVideo"
                              key={index}
                              className="user-profile1 create-post-video"
                            >
                              <source key={index} src={video} type="video/mp4" />
                            </video>
                            <Link to="#" onClick={(event) => handleClose(event, video)} className="close-video">
                              <i className="far fa-window-close"></i>
                            </Link>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : null}

                  {audioThumbnail && postFileData ? (
                    <Row>
                      {postFileData.map((audio, index) => (
                        <Col sm={12} md={12}>
                          <div className="post-img-preview-sec">
                            <audio
                              controls
                              id="myVideo"
                              className="user-profile1"
                            >
                              <source key={index} src={audio} type="audio/mp3" />
                            </audio>
                            <Link to="#" onClick={(event) => handleClose(event, audio)} className="close-audio">
                              <i className="far fa-window-close"></i>
                            </Link>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="settings-btn-sec">
                    {localStorage.getItem("is_content_creator") == 2 ? (
                      <Button
                        type="submit"
                        className="settings-submit-btn"
                        onClick={handleSubmit}
                        disabled={!inputData.message ||
                          error ||
                          props.fileUpload.buttonDisable ||
                          props.savePost.buttonDisable
                        }
                      >
                        {props.fileUpload.loadingButtonContent !== null
                          ? props.fileUpload.loadingButtonContent
                          : props.savePost.loadingButtonContent !== null
                            ? props.savePost.loadingButtonContent
                            : t("save")}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="settings-submit-btn"
                        disabled="true"
                      >
                        {t("save")}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        ) : (
          ""
        )
        }{" "}
      </Container >

    </div >
  );
};

const mapStateToPros = (state) => ({
  userMessage: state.chat.userMessage,
  savePost: state.chat.saveUserMessage,
  fileUpload: state.chat.userMessageFilesUpload,
  fileRemove: state.chat.userMessageFileRemove,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SubscribersMessageIndex));
