import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Form,
  Table,
} from "react-bootstrap";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styles from "./styles.module.css";
import "./DashboardContentCreator.css";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchContentCreatorDashboardStart } from "../../store/actions/UserAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import { translate, t } from "react-multi-lang";
import Select from "react-select";
// import DatePicker from "react-date-picker";
import DatePicker from "react-multi-date-picker"
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header"
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);


// import moment from 'moment';

const DashboardContentCreator = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState(false);
  const [clearDate, setClearDate] = useState({
    select_date: false,
    sort_by: false,
  });


  useEffect(() => {
    props.dispatch(fetchContentCreatorDashboardStart());
  }, []);

  const state = {
    labels: props.dashboard.loading
      ? ["January", "February", "March", "April", "May"]
      : props.dashboard.data.analytics.last_x_days_month,
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: props.dashboard.loading
          ? [65, 59, 80, 81, 56]
          : props.dashboard.data.analytics.last_x_days_earning,
      },
    ],
  };

  const options = [
    { value: "last_7_days", label: "Last 7 Days" },
    { value: "last_month", label: "Last Month" },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "max-content",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      // minWidth: "100%",
      fontSize: "1.4em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#000", // Set the color to white
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F5F7FB!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      fontSize: "1.4em",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1.2em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  useEffect(() => {
    if (selectedOption.value) {
      props.dispatch(
        fetchContentCreatorDashboardStart({
          sort_by: selectedOption.value,
        })
      );
      setClearDate({
        ...clearDate,
        sort_by: true,
        select_date: false,

      });
    }
  }, [selectedOption]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (startDate > date) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.dispatch(
      fetchContentCreatorDashboardStart({
        from: dayjs(startDate).format("YYYY-MM-DD"),
        to: dayjs(endDate).format("YYYY-MM-DD"),
      })
    );
    setClearDate({
      ...clearDate,
      select_date: true,
      sort_by: false,
    });
  };

  const AccordionItem = ({ header, amount, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          {/* <img className={styles.chevron} src={chevronDown} alt="Chevron Down" /> */}
          <p className={styles.chevron}>{amount}</p>
        </>
      }
      className={styles.item}
      buttonProps={{
        className: ({ isEnter }) =>
          `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
      }}
      contentProps={{ className: styles.itemContent }}
      panelProps={{ className: styles.itemPanel }}
    />
  );

  const clearConfirmation = () => {
    props.dispatch(fetchContentCreatorDashboardStart());
    setClearDate({
      ...clearDate,
      select_date: false,
      sort_by: false
    });
  }

  return (
    <>

      <div className="dashboard-content-creator-sec">
        <Container>
          <div className="dashboard-filter-sec">
            <Row>
              <Col md={8}>
                <div className="date-picker">
                  <div className="form-apply-option">
                    <h2 className="date-heading">{t("select _from_and_to_dates")}</h2>
                    {clearDate.select_date ?
                      <Link onClick={clearConfirmation}
                        className="clear-option"
                        to="#">
                        {t("clear")}
                      </Link>
                      :
                      null}
                  </div>
                  <div className="select-dates">
                    <DatePicker
                      plugins={[<DatePickerHeader position="left" />]}
                      onChange={handleStartDateChange}
                      value={startDate}
                      className="floating-inputs"
                      format={"YYYY-MM-DD"}
                      maxDate={new Date()}
                    />
                    <div>
                      <DatePicker
                        plugins={[<DatePickerHeader position="right" />]}
                        onChange={handleEndDateChange}
                        value={endDate}
                        className="floating-inputs"
                        format={"YYYY-MM-DD"}
                        maxDate={new Date()}
                      />
                    </div>
                    <Button
                      type="submit"
                      disabled={startDate > endDate}
                      className="apply-filter"
                      onClick={(e) => handleSubmit(e)}>
                      {t("apply")}
                    </Button>
                  </div>
                </div>
                {error && <p
                  className="error-msg text-danger select-date">
                  {t("from_date_val_sholud_not_be_greater_than_to_date")}
                </p>}
              </Col>
              <Col md={4}>
                <div className="form-sort">
                  <h2 className="date-heading">{t("sort_by")}</h2>
                  {clearDate.sort_by ?
                    <Link onClick={clearConfirmation}
                      className="sort-clear"
                      to="#">
                      {t("clear")}
                    </Link>
                    :
                    null}
                </div>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  styles={customStyles}
                  isSearchable={false}
                  placeholder={
                    <div className="filter-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#4b4b4b"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM14.5 20h-5c-.83 0-1.5.67-1.5 1.5S8.67 23 9.5 23h5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5zm4-9h-13c-.83 0-1.5.67-1.5 1.5S4.67 14 5.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
                      </svg>
                      {t("filter")}
                    </div>
                  }
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-theme">
                <div className="dashboard-icon-sec">
                  <i className="far fa-star"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_revenue_amount")}</h6>
                  <p>{props.dashboard.data.total_payments}</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-red">
                <div className="dashboard-icon-sec">
                  <i className="far fa-heart"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_post_amount")}</h6>
                  <p>{props.dashboard.data.post_payments}</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-blue">
                <div className="dashboard-icon-sec">
                  <i className="far fa-map"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_subscription_amount")}</h6>
                  <p>{props.dashboard.data.subscription_payments}</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-yellow ">
                <div className="dashboard-icon-sec">
                  <i className="fas fa-usd"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_tips_amount")}</h6>
                  <p>{props.dashboard.data.user_tips}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-green">
                <div className="dashboard-icon-sec">
                  <i className="fas fa-video-camera"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_live_streaming_amount")}</h6>
                  <p>{props.dashboard.data.live_video_payments}</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-green">
                <div className="dashboard-icon-sec">
                  <i className="fas fa-video-camera"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_video_call_amount")}</h6>
                  <p>{props.dashboard.data.video_call_payments}</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-green">
                <div className="dashboard-icon-sec">
                  <i className="fas fa-phone"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_audio_call_amount")}</h6>
                  <p>{props.dashboard.data.audio_call_payments}</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={6}>
              <div className="dashboard-card bg-pink">
                <div className="dashboard-icon-sec">
                  <i className="fas fa-chart-line"></i>
                </div>
                <div className="dashboard-content">
                  <h6>{t("total_chat_asset_amount")}</h6>
                  <p>{props.dashboard.data.chat_asset_payments}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="margin-to-lg">
            <Col md={6}>
              {/* <div className="dashboard-box">
                  <h3>{t("last_x_day_revenue")}</h3>
                  <div className="dashboard-chart-sec">
                    <div>
                      <Bar
                        data={state}
                        options={{
                          title: {
                            display: true,
                            text: t("last_x_day_revenue"),
                            fontSize: 20,
                          },
                          legend: {
                            display: true,
                            position: "right",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              <div className="dashboard-statistics-box">
                {props.dashboard.loading ? (
                  <div>
                    <div className="styles_accordion__KGb95">
                      <Skeleton count={1} height={56} />
                      <Skeleton count={1} height={780} />
                      <Skeleton className="mb-2" count={3} height={56} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={styles.accordion}>
                      {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
                      <Accordion transition transitionTimeout={250}>
                        <AccordionItem
                          className="demo"
                          header="All Time"
                          initialEntered
                        >
                          <Bar
                            data={state}
                            options={{
                              title: {
                                display: true,
                                text: t("last_x_day_revenue"),
                                fontSize: 20,
                              },
                              legend: {
                                display: true,
                                position: "right",
                              },
                            }}
                          />
                          <div className="dash-table">
                            <Table striped>
                              <thead>
                                <tr>
                                  <th>Earning</th>
                                  <th>Net</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                        type="radio"
                                        id="test1"
                                        name="radio-group"
                                      /> */}
                                      <label for="test1">{t("total")}</label>
                                    </div>
                                  </td>
                                  <td>
                                    {props.dashboard.data.total_payments}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test2"
                                          name="radio-group"
                                        /> */}
                                      <label for="test2">Subscription</label>
                                    </div>
                                  </td>
                                  <td>
                                    {
                                      props.dashboard.data
                                        .subscription_payments
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test3"
                                          name="radio-group"
                                        /> */}
                                      <label for="test3">Tips</label>
                                    </div>
                                  </td>
                                  <td>{props.dashboard.data.user_tips}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test4"
                                          name="radio-group"
                                        /> */}
                                      <label for="test4">Post</label>
                                    </div>
                                  </td>
                                  <td>
                                    {props.dashboard.data.post_payments}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test5"
                                          name="radio-group"
                                        /> */}
                                      <label for="test5">Chat Assets</label>
                                    </div>
                                  </td>
                                  <td>
                                    {props.dashboard.data.chat_asset_payments}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test5"
                                          name="radio-group"
                                        /> */}
                                      <label for="test5">Audio Call</label>
                                    </div>
                                  </td>
                                  <td>
                                    {props.dashboard.data.audio_call_payments}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test5"
                                          name="radio-group"
                                        /> */}
                                      <label for="test5">Video Call</label>
                                    </div>
                                  </td>
                                  <td>
                                    {props.dashboard.data.video_call_payments}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div>
                                      {/* <input
                                          type="radio"
                                          id="test5"
                                          name="radio-group"
                                        /> */}
                                      <label for="test5">Live Video</label>
                                    </div>
                                  </td>
                                  <td>
                                    {props.dashboard.data.live_video_payments}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </AccordionItem>
                        {!props.dashboard.loading &&
                          Object.keys(props.dashboard.data.analytics).length >
                          0 &&
                          props.dashboard.data.analytics.list_data.length > 0
                          ? props.dashboard.data.analytics.list_data.map(
                            (month) => (
                              <AccordionItem
                                header={month.month}
                                amount={month.earnings_formatted}
                              >
                                <Bar
                                  data={{
                                    labels: month.graph_data.x_axis_data,
                                    datasets: [
                                      {
                                        label: "Revenue",
                                        backgroundColor:
                                          "rgba(75,192,192,1)",
                                        borderColor: "rgba(0,0,0,1)",
                                        borderWidth: 2,
                                        data: month.graph_data.y_axis_data,
                                      },
                                    ],
                                  }}
                                  options={{
                                    title: {
                                      display: true,
                                      text: t("last_x_day_revenue"),
                                      fontSize: 20,
                                    },
                                    legend: {
                                      display: true,
                                      position: "right",
                                    },
                                  }}
                                />
                                <div className="dash-table">
                                  <Table striped>
                                    <thead>
                                      <tr>
                                        <th>Earning</th>
                                        <th>Net</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div>
                                            {/* <input
                                                  type="radio"
                                                  id="test11"
                                                  name="radio-group"
                                                /> */}
                                            <label for="test11">
                                              {t("total")}
                                            </label>
                                          </div>
                                        </td>
                                        <td>{month.earnings_formatted}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            {/* <input
                                                  type="radio"
                                                  id="test12"
                                                  name="radio-group"
                                                /> */}
                                            <label for="test12">
                                              {t("subscription")}
                                            </label>
                                          </div>
                                        </td>{" "}
                                        <td>
                                          {
                                            month.statistics
                                              .subscription_earnings_formatted
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <label for="test13">
                                              {t("tips")}
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            month.statistics
                                              .tips_earnings_formatted
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <label for="test14">
                                              Posts
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            month.statistics
                                              .post_earnings_formatted
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <label for="test15">
                                              Chat Assets
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            month.statistics
                                              .chat_asset_earnings_formatted
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <label for="test15">
                                              Audio Call
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            month.statistics
                                              .audio_call_earnings_formatted
                                          }
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div>
                                            <label for="test15">
                                              Video Call
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            month.statistics
                                              .video_call_earnings_formatted
                                          }
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div>
                                            <label for="test15">
                                              Live Stream
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            month.statistics
                                              .live_video_earnings_formatted
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </AccordionItem>
                            )
                          )
                          : null}
                      </Accordion>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="dashboard-box">
                {props.dashboard.loading ? (
                  <>
                    <Skeleton count={1} height={21} width={120} />
                    {
                      [...Array(5)].map(() =>
                        <div className="dashboard-user-card">
                          <div className="dashboard-user-img-sec">
                            <Skeleton count={1} height={50} width={50} circle={true} />
                          </div>
                          <div className="dashboard-user-details">
                            <Skeleton count={2} height={18} width={80} />
                          </div>
                        </div>
                      )
                    }
                  </>
                ) : (
                  <>
                    <h3 className="mb-3">{t("recent_followers")}</h3>
                    {props.dashboard.data.followers.length > 0 ? (
                      props.dashboard.data.followers.map((follower) => (
                        <div className="dashboard-user-card">
                          <div className="dashboard-user-img-sec">
                            <Image
                              className="dashboard-user-img"
                              src={follower.picture}
                            />
                          </div>
                          <div className="dashboard-user-details">
                            <h5>{follower.name}</h5>
                            <Link to={`/` + follower.u_unique_id}>
                              @{follower.username}
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoDataFound></NoDataFound>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
};

const mapStateToPros = (state) => ({
  dashboard: state.users.dashboard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(DashboardContentCreator));
