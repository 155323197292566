import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Tab,
  Nav,
  Button,
  InputGroup,
} from "react-bootstrap";
import "./Vault.css";
import { Link } from "react-router-dom";
import Select from "react-select";

import { fetchFileArchivesStart } from "../../store/actions/PostAction";
import { connect, useDispatch } from "react-redux";
import { translate, t } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";

const MobileVaultSidebar = (props) => {

  useEffect(() => {
    props.dispatch(fetchFileArchivesStart());
  }, []);

  return (
    <>
      <div className="vault-sidebar mobile-vault-sidebar">
        <div className="vault-sidebar-header-sec">
          <Link className="vault-back-btn" to={`/archive`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path d="M10.957 12.354a.5.5 0 010-.708l4.586-4.585a1.5 1.5 0 00-2.121-2.122L8.836 9.525a3.505 3.505 0 000 4.95l4.586 4.586a1.5 1.5 0 002.121-2.122z"></path>
            </svg>
            <span>{t("vault")}</span>
          </Link>
          {/* <Button className="remove-featured-story-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#fff"
              viewBox="0 0 24 24"
            >
              <path d="M17 11h-4V7a1 1 0 00-1-1 1 1 0 00-1 1v4H7a1 1 0 00-1 1 1 1 0 001 1h4v4a1 1 0 001 1 1 1 0 001-1v-4h4a1 1 0 001-1 1 1 0 00-1-1z"></path>
            </svg>
            Create vault
          </Button> */}
        </div>
        <Form className="edit-profile-form">
          <div className="vault-search-sec">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search"
              />
              <InputGroup.Text id="basic-addon2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.707 22.293l-5.969-5.969a10.016 10.016 0 10-1.414 1.414l5.969 5.969a1 1 0 001.414-1.414zM10 18a8 8 0 118-8 8.009 8.009 0 01-8 8z"></path>
                </svg>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Form>
        <div className="vault-sidebar-body-sec">
          <div className="vault-sidebar-category-box">
            <div className="vault-sidebar-category-card">
              <div className="vault-sidebar-category-info-sec">
                <h4>{t("all_media")}</h4>
                <ul className="list-unstyled vault-sidebar-category-info-list">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 20 15"
                    >
                      <path
                        fill="#B2B2B2"
                        d="M4.5 5.156c0-.778.67-1.406 1.5-1.406s1.5.628 1.5 1.406c0 .778-.67 1.407-1.5 1.407s-1.5-.629-1.5-1.407zm15-.468v5.625c0 2.587-2.24 4.687-5 4.687h-9c-2.76 0-5-2.1-5-4.688V4.689C.5 2.1 2.74 0 5.5 0h9c2.76 0 5 2.1 5 4.688zm-17 5.625c0 .721.29 1.378.77 1.884l5.24-4.913c.98-.918 2.69-.918 3.67 0l1.04.975a.64.64 0 00.85 0l3.43-3.215v-.356c0-1.547-1.35-2.813-3-2.813h-9c-1.65 0-3 1.266-3 2.813v5.625zm15 0V7.697L15.48 9.59c-.98.918-2.69.918-3.67 0l-1.04-.975a.64.64 0 00-.85 0l-4.79 4.49c.12.019.24.019.37.019h9c1.65 0 3-1.266 3-2.813z"
                      ></path>
                    </svg>
                    <span>{props.fileArchives.loading ?
                      <Skeleton count={1} width={24} height={24} />
                      : props.fileArchives.data.file_data.image}</span>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#B2B2B2"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.903 6.538a1.97 1.97 0 00-2.077.188 1.008 1.008 0 00-.11.096l-1.757 1.773C18.748 6.03 16.618 4.001 14 4.001H5A5.004 5.004 0 000 9v6c0 2.757 2.243 5 5 5h9c2.629 0 4.768-2.047 4.962-4.627l1.756 1.754c.034.033.069.063.107.092a1.975 1.975 0 002.078.187A1.973 1.973 0 0024 15.632V8.314c0-.757-.42-1.437-1.097-1.775zM14 18H5c-1.654 0-3-1.346-3-3V9c0-1.654 1.346-3 3-3h9c1.654 0 3 1.346 3 3v6c0 1.654-1.346 3-3 3zm5-5.417v-1.189l3-3.028.025 7.238L19 12.582z"></path>
                    </svg>
                    <span>{props.fileArchives.loading ?
                      <Skeleton count={1} width={24} height={24} />
                      : props.fileArchives.data.file_data.video}</span>
                  </li>
                </ul>
              </div>
              <div className="vault-sidebar-category-assets-img-sec">
                {props.fileArchives.loading ?
                  [...Array(3)].map((i) =>
                    <Skeleton height={45} width={45} key={i} />)
                  :
                  props.fileArchives.data.file_data.all_files.map((allFile, i) =>
                    <Image
                      className="vault-sidebar-category-assets-img"
                      src={allFile}
                    />
                  )}

                {/* 
                <div className="vault-sidebar-category-assets-img-count-sec">
                  10
                </div> */}
              </div>
            </div>
            <div className="vault-sidebar-category-card">
              <div className="vault-sidebar-category-info-sec">
                <h4>{t("images")}</h4>
                <ul className="list-unstyled vault-sidebar-category-info-list">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 20 15"
                    >
                      <path
                        fill="#B2B2B2"
                        d="M4.5 5.156c0-.778.67-1.406 1.5-1.406s1.5.628 1.5 1.406c0 .778-.67 1.407-1.5 1.407s-1.5-.629-1.5-1.407zm15-.468v5.625c0 2.587-2.24 4.687-5 4.687h-9c-2.76 0-5-2.1-5-4.688V4.689C.5 2.1 2.74 0 5.5 0h9c2.76 0 5 2.1 5 4.688zm-17 5.625c0 .721.29 1.378.77 1.884l5.24-4.913c.98-.918 2.69-.918 3.67 0l1.04.975a.64.64 0 00.85 0l3.43-3.215v-.356c0-1.547-1.35-2.813-3-2.813h-9c-1.65 0-3 1.266-3 2.813v5.625zm15 0V7.697L15.48 9.59c-.98.918-2.69.918-3.67 0l-1.04-.975a.64.64 0 00-.85 0l-4.79 4.49c.12.019.24.019.37.019h9c1.65 0 3-1.266 3-2.813z"
                      ></path>
                    </svg>
                    <span>{props.fileArchives.loading ?
                      <Skeleton count={1} width={24} height={24} />
                      : props.fileArchives.data.file_data.image}</span>
                  </li>
                </ul>
              </div>
              <div className="vault-sidebar-category-assets-img-sec">
                {props.fileArchives.loading ?
                  [...Array(3)].map((i) =>
                    <Skeleton height={45} width={45} key={i} />)
                  :
                  props.fileArchives.data.file_data.video_files.map((video, i) =>
                    <Image
                      className="vault-sidebar-category-assets-img"
                      src={video}
                    />
                  )}
                {/* <div className="vault-sidebar-category-assets-img-count-sec">
                  10
                </div> */}
              </div>
            </div>
            <div className="vault-sidebar-category-card">
              <div className="vault-sidebar-category-info-sec">
                <h4>{t("videos")}</h4>
                <ul className="list-unstyled vault-sidebar-category-info-list">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 20 15"
                    >
                      <path
                        fill="#B2B2B2"
                        d="M4.5 5.156c0-.778.67-1.406 1.5-1.406s1.5.628 1.5 1.406c0 .778-.67 1.407-1.5 1.407s-1.5-.629-1.5-1.407zm15-.468v5.625c0 2.587-2.24 4.687-5 4.687h-9c-2.76 0-5-2.1-5-4.688V4.689C.5 2.1 2.74 0 5.5 0h9c2.76 0 5 2.1 5 4.688zm-17 5.625c0 .721.29 1.378.77 1.884l5.24-4.913c.98-.918 2.69-.918 3.67 0l1.04.975a.64.64 0 00.85 0l3.43-3.215v-.356c0-1.547-1.35-2.813-3-2.813h-9c-1.65 0-3 1.266-3 2.813v5.625zm15 0V7.697L15.48 9.59c-.98.918-2.69.918-3.67 0l-1.04-.975a.64.64 0 00-.85 0l-4.79 4.49c.12.019.24.019.37.019h9c1.65 0 3-1.266 3-2.813z"
                      ></path>
                    </svg>
                    <span>{props.fileArchives.loading ?
                      <Skeleton count={1} width={24} height={24} />
                      : props.fileArchives.data.file_data.video}</span>
                  </li>

                </ul>
              </div>
              <div className="vault-sidebar-category-assets-img-sec">
                {props.fileArchives.loading ?
                  [...Array(3)].map((i) =>
                    <Skeleton height={45} width={45} key={i} />)
                  :
                  props.fileArchives.data.file_data.image_files.map((images, i) =>
                    <Image
                      className="vault-sidebar-category-assets-img"
                      src={images}
                    />
                  )}
                
                {/* <div className="vault-sidebar-category-assets-img-count-sec">
                  10
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  fileArchives: state.post.fileArchives,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(MobileVaultSidebar));
