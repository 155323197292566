import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Tab,
  Nav,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import "./Vault.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import VaultSidebar from "./VaultSidebar";
import VaultMainWrapper from "./VaultMainWrapper";
import MobileVaultSidebar from "./MobileVaultSidebar";

const VaultIndex = () => {

  const [toggle, setToggle] = useState(false)

  return (
    <>
      <div className="vault-sec">
        <div className="vault-box">
          <VaultSidebar/>
          <div className="mobile-vault-sidebar-toogle-btn" onClick={() => setToggle(!toggle)} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#6661e7"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
            </svg>
          </div>
          {toggle && (
            <div>
              <Button className="btn-close-vault-sidebar" onClick={() => setToggle(!toggle)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#6661e7"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                </svg>
              </Button>
              <MobileVaultSidebar/>
            </div>
          )}
          <VaultMainWrapper/>
        </div>
      </div>
    </>
  );
};

export default VaultIndex;
