import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Image, Modal, Tab, Row, Col, Nav, FormCheck, InputGroup } from "react-bootstrap";
import VaultChatMainWrapper from "./VaultChatMainWrapper";
import MobileVaultSidebar from "./MobileVaultSidebar";
import VaultSidebar from "./VaultSidebar";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

function VaultModal(props) {



  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="vaultmodal"
      onHide={props.onHide}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="vault-sec">
          <div className="vault-box">
            <VaultSidebar />
            {/* <MobileVaultSidebar/> */}
            <VaultChatMainWrapper
              message={props.message}
              setMessage={props.setMessage}
              handleMessageSubmit={props.handleMessageSubmit} 
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  chatFilesUpload: state.chat.chatFilesUpload,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VaultModal));