import configuration from "react-global-configuration";

export function tokenAmount(amount = 0.0, is_currency = false) {
  let oneToken = configuration.get("configData.token_amount");
  amount = oneToken * amount;
  if (is_currency) {
    return configuration.get("configData.currency") + " " + amount;
  }

  return amount;
}
export function vatAmount(amount = 0.0, is_currency = false) {
  let tokenAmount = configuration.get("configData.token_amount") * amount;
  let vatPercen = configuration.get("configData.vat_percentage") / 100;
  let vatAmount = tokenAmount * vatPercen;
  vatAmount = Math.round((vatAmount + Number.EPSILON) * 100) / 100;

  if (is_currency) {
    return configuration.get("configData.currency") + " " + vatAmount;
  }
  return vatAmount;
}
export function totalAmount(amount = 0.0, is_currency = false) {
  let tokenAmount = configuration.get("configData.token_amount") * amount;
  let vatPercen = configuration.get("configData.vat_percentage") / 100;
  let vatAmount = tokenAmount * vatPercen;
  vatAmount = Math.round((vatAmount + Number.EPSILON) * 100) / 100;
  amount = vatAmount + tokenAmount;
  amount = Math.round((amount + Number.EPSILON) * 100) / 100;
  if (is_currency) {
    return configuration.get("configData.currency") + " " + amount;
  }
  return amount;
}
