import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Tab,
  Nav,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import "./Vault.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import VaultSidebar from "./VaultSidebar";
import VaultModal from "./VaultModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchFileArchivesStart, fetchMoreFileArchivesStart } from "../../store/actions/PostAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { translate, t } from "react-multi-lang";
import { connect, useDispatch } from "react-redux";
import FancyBox from "../NewHome/NewSingleView/FancyBox";
import Skeleton from 'react-loading-skeleton';
import CustomLazyLoad from "../helper/CustomLazyLoad";


const VaultMainWrapper = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const options = [
    // { value: 'all time', label: 'All Time' },
    // { value: 'go to date', label: 'Go to Date' },
    // { value: 'recent', label: 'Recent' },
    // { value: 'most liked', label: 'Most Liked' },
    // { value: 'highest tips', label: 'Highest Tips' },
    // { value: 'ascending', label: 'Ascending' },
    // { value: 'descending', label: 'Descending' },
    { value: 'ascending', label: 'Ascending' },
    { value: 'descending', label: 'Descending' }

  ]

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "max-content",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      // minWidth: "100%",
      fontSize: "1.4em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F5F7FB!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "55px",
      minWidth: "auto",
      fontSize: "1.4em",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const [fileType, setFileType] = useState('all');

  const fetchMoreAssets = () => {
    props.dispatch(fetchMoreFileArchivesStart({
      skip: props.fileArchives.data.file_archives.length,
      take: 12,
      file_type: fileType,
    }));
  }

  useEffect(() => {
    props.dispatch(fetchFileArchivesStart({
      file_type: fileType,
    }));
  }, [fileType]);

  return (
    <>
      <div className="vault-main-wrapper">
        <Form className="edit-profile-form">
          <div className="vault-main-header-sec">
            <h3>All Media</h3>
            <div className="vault-main-header-right-sec">
              <div className="vault-main-header-search-sec">
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Search"
                  />
                  <InputGroup.Text id="basic-addon2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.707 22.293l-5.969-5.969a10.016 10.016 0 10-1.414 1.414l5.969 5.969a1 1 0 001.414-1.414zM10 18a8 8 0 118-8 8.009 8.009 0 01-8 8z"></path>
                    </svg>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="vault-main-filter-sec">
                <Select
                  options={options}
                  // menuIsOpen={true}
                  styles={customStyles}
                  isSearchable={false}
                  placeholder={
                    <div className="filter-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#4b4b4b"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM14.5 20h-5c-.83 0-1.5.67-1.5 1.5S8.67 23 9.5 23h5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5zm4-9h-13c-.83 0-1.5.67-1.5 1.5S4.67 14 5.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
                      </svg>
                      Sort
                    </div>}
                />
              </div>
            </div>
          </div>
          <div className="vault-main-body-sec ">
            <div className="vault-main-body-nav-tab-link-sec-1">
              <div className={`new-chat-media-tab-nav-link ${fileType === "all" ? "active" : ""}`} onClick={() => setFileType("all")}>
                <span>
                  <Image
                    className="profile-post-tab-icon"
                    src={
                      window.location.origin + "/assets/images/new-home/icon/all-post-1.svg"
                    }
                  />
                </span>
                <span className="resp-display-none">{t("all")}</span>
              </div>
              <div className={`new-chat-media-tab-nav-link ${fileType === "image" ? "active" : ""}`} onClick={() => setFileType("image")}>
                <span>
                  <Image
                    className="profile-post-tab-icon"
                    src={
                      window.location.origin + "/assets/images/new-home/icon/image-post-1.svg"
                    }
                  />
                </span>
                <span className="resp-display-none">{t("image")}</span>
              </div>
              <div className={`new-chat-media-tab-nav-link ${fileType === "video" ? "active" : ""}`} onClick={() => setFileType("video")}>
                <span>
                  <Image
                    className="profile-post-tab-icon"
                    src={
                      window.location.origin + "/assets/images/new-home/icon/video-post-1.svg"
                    }
                  />
                </span>
                <span className="resp-display-none">{t("video")}</span>
              </div>
            </div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
              <Row>
                <Col sm={12}>
                  <div className="vault-main-body-nav-tab-all-box">
                    {props.fileArchives.loading ?
                      <div className="vault-main-body-nav-tab-all-card">
                        {[...Array(4)].map((val, i) =>
                          <Skeleton height={200} key={i} />)}
                      </div>
                      : props.fileArchives.data.file_archives ?
                        <FancyBox delegate={"[data-fancybox-asset]"} options={{ groupAll: true }}>
                          {props.fileArchives.data.file_archives.length > 0 ?
                            <InfiniteScroll
                              dataLength={props.fileArchives.data.file_archives.length}
                              next={fetchMoreAssets}
                              hasMore={props.fileArchives.data.file_archives.length < props.fileArchives.data.total}
                              loader={<div className="vault-main-body-nav-tab-all-card">
                                {[...Array(4)].map((val, i) =>
                                  <Skeleton height={200} key={i} />)}
                              </div>}
                              // style={{ padding: '2em', display: "flex", flexDirection: "column-reverse", overflow: "inherit" }}
                              scrollableTarget="assetDiv"
                            >
                              <div className="vault-main-body-nav-tab-all-card">
                                {props.fileArchives.data.file_archives.map((file_archive, i) =>
                                  <FileAsset
                                    file_archive={file_archive}
                                    key={i}
                                  />
                                )}
                              </div>
                            </InfiniteScroll>
                            : <div className="text-center">
                              <Image
                                src={
                                  fileType === "image" || fileType === "all"  ?
                                    window.location.origin + "/assets/images/new-chat/no-image-found.png"
                                    : fileType === "video" ?
                                      window.location.origin + "/assets/images/new-chat/no-video-found.png" : ''
                                }
                                className="no-data-media-img"
                              />
                            </div>}
                        </FancyBox>
                        : null}
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Form>
      </div>
    </>
  );
};


const FileAsset = ({ file_archive }) => {
  const dispatch = useDispatch();
  const assetImage = useRef(null);

  return <>
    <div className="custom-control custom-checkbox image-checkbox">
      <input type="checkbox" class="custom-control-input" id="ck1a" />
      <div className="vault-card-icons-part">
        <div className="imagedate"><p>{file_archive.created}</p></div>
        <div className="clipicon">
          {file_archive.file_type == 'image' ?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 20 15"
            >
              <path
                fill="#B2B2B2"
                d="M4.5 5.156c0-.778.67-1.406 1.5-1.406s1.5.628 1.5 1.406c0 .778-.67 1.407-1.5 1.407s-1.5-.629-1.5-1.407zm15-.468v5.625c0 2.587-2.24 4.687-5 4.687h-9c-2.76 0-5-2.1-5-4.688V4.689C.5 2.1 2.74 0 5.5 0h9c2.76 0 5 2.1 5 4.688zm-17 5.625c0 .721.29 1.378.77 1.884l5.24-4.913c.98-.918 2.69-.918 3.67 0l1.04.975a.64.64 0 00.85 0l3.43-3.215v-.356c0-1.547-1.35-2.813-3-2.813h-9c-1.65 0-3 1.266-3 2.813v5.625zm15 0V7.697L15.48 9.59c-.98.918-2.69.918-3.67 0l-1.04-.975a.64.64 0 00-.85 0l-4.79 4.49c.12.019.24.019.37.019h9c1.65 0 3-1.266 3-2.813z"
              ></path>
            </svg>
            :
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#B2B2B2"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M22.903 6.538a1.97 1.97 0 00-2.077.188 1.008 1.008 0 00-.11.096l-1.757 1.773C18.748 6.03 16.618 4.001 14 4.001H5A5.004 5.004 0 000 9v6c0 2.757 2.243 5 5 5h9c2.629 0 4.768-2.047 4.962-4.627l1.756 1.754c.034.033.069.063.107.092a1.975 1.975 0 002.078.187A1.973 1.973 0 0024 15.632V8.314c0-.757-.42-1.437-1.097-1.775zM14 18H5c-1.654 0-3-1.346-3-3V9c0-1.654 1.346-3 3-3h9c1.654 0 3 1.346 3 3v6c0 1.654-1.346 3-3 3zm5-5.417v-1.189l3-3.028.025 7.238L19 12.582z"></path>
            </svg>}
        </div>
      </div>
      {file_archive.file_type === "image" ?
        <label className="custom-control-label" for="ck1a">
          <CustomLazyLoad
            src={file_archive.preview_file}
            className={"vault-main-body-nav-tab-img"}
            data-fancybox-asset
          />
        </label>
        :
        <label className="custom-control-label" for="ck1a">
          <Image
            className="vault-main-body-nav-tab-img"
            src={file_archive.preview_file}
            data-fancybox-asset
            ref={assetImage}
            href={file_archive.file}
          />
          <div className="cursor-pointer" onClick={() => assetImage.current.click()}>
            <Image
              src={window.location.origin + "/assets/images/new-home/icon/video-icon.png"}
              className="new-chat-user-media-video-icon"
            />
          </div>
        </label>
      }
    </div>
  </>
}


const mapStateToPros = (state) => ({
  fileArchives: state.post.fileArchives,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(VaultMainWrapper));

