import React, { useState, createRef, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, FormCheck } from "react-bootstrap";
import "./SubscribeMessage.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { sendBulkMessageStart, chatAssetFilesUploadStart } from "../../../store/actions/ChatAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchActiveFollowersStart,
} from "../../../store/actions/FollowAction";

const BulkMessageIndex = (props) => {

  const [inputData, setInputData] = useState({ user_type: '', message: '', file_type: '', amount: '', user_ids: '' });
  const [error, setError] = useState(false);

  const handleChangeAmount = (e) => {
    const value = e.target.value;
    setInputData({
      ...inputData,
      amount: value,
    })
    validateNumber(value);
  }

  const validateNumber = (value) => {
    if (value < 0) {
      setError(true);
    } else {
      setError(false);
    }
  }

  const [selectOption, setSelectOption] = useState([]);
  const [triggeredOnce, setTriggeredOnce] = useState(false);
  const [fileData, setFileData] = useState('');
  const [fileImageData, setFileImageData] = useState([]);
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    props.dispatch(fetchActiveFollowersStart());
  }, []);


  useEffect(() => {
    if (!props.activeFollowers.loading && props.activeFollowers.data) {
      let options = [];
      props.activeFollowers.data.followers.map((follower, i) => {
        options.push({ value: follower.u_id, label: follower.name });
      });
      setSelectOption(options);
    }
  }, [props.activeFollowers]);

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#F5F7FB!important',
      border: '0!important',
      boxShadow: '0px 0px 10px rgb(0 0 0 / 10%);!important',
      height: '45px',
      // minWidth: "150px",
      fontSize: "1.4em",
      fontWeight: "500"

    }),
    singleValue: provided => ({
      ...provided, color: '#989CA1', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "1.2em", fontWeight: "600"
    }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#4b4b4b!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        fontSize: "1.5em",
        fontWeight: "600"
      };
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(sendBulkMessageStart({
      file_type: inputData.file_type,
      user_type: inputData.user_type,
      amount: inputData.amount,
      message: inputData.message,
      user_ids: inputData.user_ids,
      ...fileData,
    }));
    // props.dispatch(sendBulkMessageStart(inputData));
  };

  const handleChange = (selectedOption) => {
    let user_ids = [];
    selectedOption.map((follower, i) => {
      user_ids.push(follower.value);
    });
    setInputData({
      ...inputData,
      user_ids: user_ids,
    })
  }

  const handleFileSelect = (e, file_type) => {
    let files = {};

    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles]
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file))
    })
    setFileImageData(selectedFIles);
    setFileType(file_type);

    if (e.target.files.length > 0) {
      [...e.target.files].forEach((file, key) => {
        let name = 'file[' + key + ']';
        files = { ...files, [name]: file }
      });
      setFileData(files);
      setInputData({
        ...inputData,
        file_type: file_type,
      })
      setTriggeredOnce(true);
      e.target.value = null;
    }
  }

  const removeFile = (e, index, file) => {
    e.preventDefault();
    const items = fileImageData.filter(item => item !== file);
    setFileImageData(items);

    setFileData(current => {
      const copy = { ...current };
      delete copy['file[' + index + ']'];
      return copy;
    });
  }

  return (
    <>
      <div className="bulk-message-sec">
        <Container>
          <div className="settings-personal-info-header">
            <h3>Bulk Message</h3>
          </div>
          <Form className="bulk-message-form-sec edit-profile-form" onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="bulk-message-header-sec">
                  <div className="bulk-message-all-fans">
                    <label>
                      <input
                        type="radio"
                        name="user_type"
                        value="1"
                        onChange={(event) =>
                          setInputData({
                            ...inputData,
                            user_type: event.currentTarget.value,
                          })
                        }
                      />
                      <span>All Fans</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="user_type"
                        value="2"
                        onChange={(event) =>
                          setInputData({
                            ...inputData,
                            user_type: event.currentTarget.value,
                          })
                        }
                      />
                      <span>Selected Users</span>
                    </label>
                  </div>
                  {inputData.user_type == 2 ?
                    <div className="bulk-message-user-select-sec">
                      <Select
                        options={selectOption}
                        onChange={handleChange}
                        styles={
                          customStyles}
                        isMulti
                        isSearchable={false}
                        placeholder={<div>Enter User Name</div>}
                      />
                    </div>
                    : ''}
                  <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="number"
                      name="amount"
                      placeholder="Enter the Amount"
                      onChange={handleChangeAmount}
                    />
                    {error && <p className="error-msg text-danger text-right">{t("should_not_be_less_than")}</p>}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="bulk-mesage-text-area">
                  <Form.Label>Message</Form.Label>
                  <Form.Group className="mb-0" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Your Message"
                      name="message"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          message: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="bulk-message-assets-content">
                  <div className="bulk-message-upload-assets-box">
                    <div className="bulk-message-upload-image-card">
                      <Button>
                        <Form.Group className="mb-0">
                          <Form.Control
                            id="fileupload_photo"
                            type="file"
                            multiple="multiple"
                            accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                            onChange={(event) =>
                              handleFileSelect(event, "image")
                            }
                            name="post_files"
                          />
                          <Form.Label
                            id="attach_file_photo"
                            for="fileupload_photo"
                            className="chat-attach_file"
                            data-original-title="null"
                          >
                            <div className="bulk-message-upload-image-item">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5zM5 2h14a3 3 0 013 3v14a2.951 2.951 0 01-.3 1.285l-9.163-9.163a5 5 0 00-7.072 0L2 14.586V5a3 3 0 013-3zm0 20a3 3 0 01-3-3v-1.586l4.878-4.878a3 3 0 014.244 0l9.163 9.164A2.951 2.951 0 0119 22z"></path>
                                <path d="M16 10.5A3.5 3.5 0 1012.5 7a3.5 3.5 0 003.5 3.5zm0-5A1.5 1.5 0 1114.5 7 1.5 1.5 0 0116 5.5z"></path>
                              </svg>
                              <span>Image Upload</span>
                            </div>
                          </Form.Label>
                        </Form.Group>
                      </Button>

                    </div>
                    <div className="bulk-message-upload-video-card">
                      <Button>
                        <Form.Group className="mb-0">
                          <Form.Control
                            id="fileupload_video"
                            type="file"
                            multiple="multiple"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={(event) =>
                              handleFileSelect(event, "video")
                            }
                            name="post_files"
                          />
                          <Form.Label
                            id="attach_file_video"
                            for="fileupload_video"
                            className="chat-attach_file"
                            data-original-title="null"
                          >
                            <div className="bulk-message-upload-video-item">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M22.903 6.538a1.97 1.97 0 00-2.077.188 1.008 1.008 0 00-.11.096l-1.757 1.773C18.748 6.03 16.618 4.001 14 4.001H5A5.004 5.004 0 000 9v6c0 2.757 2.243 5 5 5h9c2.629 0 4.768-2.047 4.962-4.627l1.756 1.754c.034.033.069.063.107.092a1.975 1.975 0 002.078.187A1.973 1.973 0 0024 15.632V8.314c0-.757-.42-1.437-1.097-1.775zM14 18H5c-1.654 0-3-1.346-3-3V9c0-1.654 1.346-3 3-3h9c1.654 0 3 1.346 3 3v6c0 1.654-1.346 3-3 3zm5-5.417v-1.189l3-3.028.025 7.238L19 12.582z"></path>
                              </svg>
                              <span>Video Upload</span>
                            </div>
                          </Form.Label>
                        </Form.Group>
                      </Button>
                    </div>
                    <div className="bulk-message-upload-audio-card">
                      <Button>
                        <Form.Group className="mb-0">
                          <Form.Control
                            id="fileupload_audio"
                            type="file"
                            multiple="multiple"
                            accept="audio/mp3,audio/*"
                            onChange={(event) =>
                              handleFileSelect(event, "audio")
                            }
                            name="post_files"
                          />
                          <Form.Label
                            id="attach_file_audio"
                            for="fileupload_audio"
                            className="chat-attach_file"
                            data-original-title="null"
                          >
                            <div className="bulk-message-upload-audio-item">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M15 7h3v10h-3V7zm-5 17h3V0h-3v24zM20 4v16h3V4h-3zM5 19h3V5H5v14zm-5-4h3V9H0v6z"></path>
                              </svg>
                              <span>Audio Upload</span>
                            </div>
                          </Form.Label>
                        </Form.Group>
                      </Button>


                    </div>
                  </div>
                  <div className="bulk-message-upload-preview-option-box">
                    {fileImageData.length > 0 ? (
                      <>
                        {fileImageData.map((file, index) => (

                          <div className="bulk-message-upload-preview-option-card" key={index}>
                            <div className="bulk-message-upload-preview-option-img-card">
                              {fileType == 'image' ?
                                <Image
                                  className="bulk-message-upload-preview-option-img"
                                  src={file}
                                />
                                : fileType == 'video' ?
                                  <video
                                    autoplay
                                    controls
                                    id="myVideo"
                                    className="user-profile1 create-post-video"
                                  >
                                    <source src={file} type="video/mp4" />
                                  </video>
                                  : fileType == "audio" ?
                                    <audio
                                      controls
                                      id="myVideo"
                                      className="user-profile1"
                                    >
                                      <source src={file} type="audio/mp3" />
                                    </audio>
                                    : ''}
                              <Button
                                className="bulk-message-upload-preview-close-btn"
                                onClick={(event) => removeFile(event, index, file)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                  fill="#dc3545"
                                >
                                  <path d="M19 2H5C2.243 2 0 4.243 0 7v10c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5zm3 15c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V7c0-1.654 1.346-3 3-3h14c1.654 0 3 1.346 3 3v10zm-5.793-7.793L13.414 12l2.793 2.793a.999.999 0 11-1.414 1.414L12 13.414l-2.793 2.793a.997.997 0 01-1.414 0 .999.999 0 010-1.414L10.586 12 7.793 9.207a.999.999 0 111.414-1.414L12 10.586l2.793-2.793a.999.999 0 111.414 1.414z"></path>
                                </svg>
                              </Button>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : ''}

                  </div>
                </div>
                <div className="settings-btn-sec">
                  <Button
                    type="submit"
                    className="settings-submit-btn"
                    onClick={handleSubmit}
                    disabled={!inputData.amount || error  || !inputData.message || !fileType ||
                      props.bulkMessages.buttonDisable
                    }
                  >{props.bulkMessages.loadingButtonContent !== null
                    ? props.bulkMessages.loadingButtonContent
                    : t("submit")}</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bulkMessages: state.chat.bulkMessages,
  activeFollowers: state.follow.activeFollowers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(BulkMessageIndex));

